const theme = {
    font: {
        fontName: "Kiro",
        fontFamily: "Kiro, sans-serif",
        fontSize: "16px"
    },
    site: {
        siteWidth: "1600px",
        document: "748px",
        menuWidth: "350px"
    },
    palette: {
        blue: [
            "#EBF8FF",
            "#D1EEFC",
            "#A7D8F0",
            "#7CC1E4",
            "#55AAD4",
            "#3994C1",
            "#2D83AE",
            "#1D6F98",
            "#166086",
            "#0B4F71"
        ],
        green: [
            "#E3F9E5",
            "#C1EAC5",
            "#A3D9A5",
            "#7BC47F",
            "#57AE5B",
            "#3F9142",
            "#2F8132",
            "#207227",
            "#0E5814",
            "#05400A"
        ],
        grey: [
            "#F5F7FA",
            "#E4E7EB",
            "#CBD2D9",
            "#9AA5B1",
            "#7B8794",
            "#616E7C",
            "#52606D",
            "#3E4C59",
            "#323F4B",
            "#1F2933"
        ],
        purple: [
            "#EAE2F8",
            "#CFBCF2",
            "#A081D9",
            "#8662C7",
            "#724BB7",
            "#653CAD",
            "#51279B",
            "#421987",
            "#34126F",
            "#240754"
        ],
        red: [
            "#FFEEEE",
            "#FACDCD",
            "#F29B9B",
            "#E66A6A",
            "#D64545",
            "#BA2525",
            "#A61B1B",
            "#911111",
            "#780A0A",
            "#610404"
        ],
        yellow: [
            "#FFFAEB",
            "#FCEFC7",
            "#F8E3A3",
            "#F9DA8B",
            "#F7D070",
            "#E9B949",
            "#C99A2E",
            "#A27C1A",
            "#7C5E10",
            "#513C06"
        ]
    },
    fontSize: [
        "12px",
        "14px",
        "16px",
        "18px",
        "20px",
        "24px",
        "30px",
        "36px",
        "48px",
        "60px",
        "72px"
    ],
    spacing: [
        "4px",
        "8px",
        "12px",
        "16px",
        "24px",
        "32px",
        "48px",
        "64px",
        "96px",
        "128px",
        "192px",
        "256px",
        "384px",
        "512px",
        "640px",
        "768px",
        "1200px"
    ],
    borderRadius: [".125rem", ".25rem", ".5rem", "9999px"],
    boxShadow: [
        "0 1px 3px hsla(0, 0%, 0%, 0.2)",
        "0 4px 6px hsla(0, 0%, 0%, 0.2)",
        "0 5px 15px hsla(0, 0%, 0%, 0.2)",
        "0 10px 24px hsla(0, 0%, 0%, 0.2)",
        "0 15px 35px hsla(0, 0%, 0%, 0.2)",
        "0 25px 50px -12px hsla(0, 0%, 0%, 0.25)",
        "0px 0px 24px 0px rgba(97,97,97,0.5)",
        "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)"
    ]
};

export default theme;
