import React from "react";
import {createGlobalStyle} from "styled-components";
import theme from "./src/config/theme";

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        font-family: ${theme.font.fontFamily};
        font-size: ${theme.font.fontSize};
    }
    * {
        box-sizing: border-box;
    }
`;

export const wrapRootElement = ({element}) => { /* eslint-disable-line */
    return (
        <>
            <GlobalStyle />
            {element}
        </>
    );
};
